import React, {useState} from 'react';
import update from 'immutability-helper';
import {Checkbox, ChoiceList, Spinner, LegacyStack} from '@shopify/polaris';
import getSellingPlans from '../../../../../helpers/getSellingPlans';

const SubscriptionOption = (props) => {
  const {state, setState, selectedProduct, index, token, shop, host} = props;
  const [sellingPlansLoading, setSellingPlansLoading] = useState(false)

  const handleSubscriptionChange = async (newChecked, index, productId) => {
    index !== null
      ? setState(update(state, {
        product: {
          [index]: {
            product: {subscription: {$set: newChecked}}
          }
        }
      }))
      : setState(update(state, {product: {subscription: {$set: newChecked}}}))

    if (newChecked) {
      const id = `gid://shopify/Product/${String(productId).replace('gid://shopify/Product/', '')}`
      setSellingPlansLoading(true)
      const sellingPlans = await getSellingPlans({id: id}, token, shop, host)
      setSellingPlansLoading(false)

      if (200 === sellingPlans.status && sellingPlans.data.data && sellingPlans.data.data.edges) {
        index !== null
          ? setState(update(state, {
            product: {
              [index]: {
                product: {subscription: {$set: {
                  sellingPlans: sellingPlans.data.data.edges,
                  choosedSellingPlans: selectedProduct?.subscription?.choosedSellingPlans || [sellingPlans.data.data.edges[0]?.node?.id]
                }}}
              }
            }
          }))
          : setState(update(state, {product: {subscription: {$set: {
            sellingPlans: sellingPlans.data.data.edges,
            choosedSellingPlans: selectedProduct?.subscription?.choosedSellingPlans || [sellingPlans.data.data.edges[0]?.node?.id]
          }}}}))

      } else {
        index !== null
          ? setState(update(state, {
            product: {
              [index]: {
                product: {subscription: {$set: {sellingPlans: [], choosedSellingPlans: null}}}
              }
            }
          }))
          : setState(update(state, {product: {subscription: {$set: {sellingPlans: [], choosedSellingPlans: null}}}}))
      }
    }
  }

  const handleChooseSellingPlan = (value, index) => {
    index !== null
      ? setState(update(state, {
        product: {
          [index]: {
            product: {
              subscription: {
                $set: {
                  sellingPlans: state.product[index].product.subscription.sellingPlans,
                  choosedSellingPlans: value
                }
              }
            }
          }
        }
      }))
      : setState(update(state, {
        product: {
          subscription: {
            $set: {
              sellingPlans: state.product.subscription.sellingPlans,
              choosedSellingPlans: value
            }
          }
        }
      }))
  }

  return (
    <LegacyStack vertical spacing='tight'>
      <Checkbox
        label="Add as a subscription"
        checked={selectedProduct?.subscription ? true : false}
        onChange={val => handleSubscriptionChange(val, index, selectedProduct.id)}
      />
      {
        sellingPlansLoading
          ? <Spinner accessibilityLabel="Loading Selling Plans" size="small"/>
          : false
      }
      {
        selectedProduct?.subscription && selectedProduct.subscription.sellingPlans && Array.isArray(selectedProduct.subscription.sellingPlans) && selectedProduct.subscription.sellingPlans.length === 0
          ? <div className="Polaris-Labelled__HelpText"><span>There is no selling plan for this product. Please create selling plan first.</span>
          </div>
          : false
      }
      {
        selectedProduct?.subscription && selectedProduct.subscription.sellingPlans && Array.isArray(selectedProduct.subscription.sellingPlans) && selectedProduct.subscription.sellingPlans.length > 0
          ? <ChoiceList
            title="Choose a selling plan"
            choices={selectedProduct.subscription.sellingPlans.map((item) => {
              return {label: item.node.name, value: item.node.id}
            })}
            selected={selectedProduct.subscription.choosedSellingPlans ? selectedProduct.subscription.choosedSellingPlans : [selectedProduct.subscription.sellingPlans[0].id]}
            onChange={value => handleChooseSellingPlan(value, index)}
            titleHidden
          />
          : false
      }
    </LegacyStack>
  );
};

export default SubscriptionOption;